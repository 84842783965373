import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOnOutsideDirective } from './click-on-outside/click-on-outside.directive';
import { InputDigitsDirective } from './input-digits/input-digits.directive';
import { AutofocusDirective } from './autofocus/autofocus.directive';
import { FindAgentByEmailDirective } from './find-agent-by-email/find-agent-by-email.directive';
import { CurrencyFormatterDirective } from './currencyFormatter/currency-formatter.directive';
import { PreventTypingDirective } from './prevent-typing/prevent-typing.directive';
import { FileDragAndDropDirective } from './file-drag-and-drop/file-drag-drop.directive';
import { PhoneValidatorDirective } from './phone-validator/phone-validator.directive';
import { ContactsAutoCompleteDirective } from './contacts-auto-complete/contacts-auto-complete.directive';

@NgModule({
  imports: [CommonModule, ContactsAutoCompleteDirective],
  declarations: [
    ClickOnOutsideDirective,
    InputDigitsDirective,
    AutofocusDirective,
    FindAgentByEmailDirective,
    CurrencyFormatterDirective,
    PreventTypingDirective,
    FileDragAndDropDirective,
    PhoneValidatorDirective,
  ],
  exports: [
    ClickOnOutsideDirective,
    InputDigitsDirective,
    AutofocusDirective,
    FindAgentByEmailDirective,
    CurrencyFormatterDirective,
    PreventTypingDirective,
    FileDragAndDropDirective,
    PhoneValidatorDirective,
    ContactsAutoCompleteDirective,
  ],
})
export class DirectivesModule {}
